import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import ObraAeropuerto from './Components/Obras/ObraAeropuerto';
import ObraHotelSalta from './Components/Obras/ObraHotelSalta';
import ObraMebac from './Components/Obras/ObraMebac';
import ObraConectar from './Components/Obras/ObraConectar';
import ObraYarade from './Components/Obras/ObraYarade';
import ObraSeil from './Components/Obras/ObraSeil';
import ReactGA from 'react-ga';


function App() {

  useEffect(() => {
    ReactGA.initialize('G-T8HY4JNP03'); // Replace with your actual tracking ID
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/AA2000" element={<ObraAeropuerto />} />
          <Route path="/HotelSalta" element={<ObraHotelSalta />} />
          <Route path="/MEBAC" element={<ObraMebac />} />
          <Route path="/ProyectoConectar" element={<ObraConectar />} />
          <Route path="/FYyAS" element={<ObraYarade />} />
          <Route path="/Seil" element={<ObraSeil />} />

        </Routes>
      </div>
    </BrowserRouter>
  );
}
export default App;


